<template>
  <div class="publications">
    <page-header :title="title" icon="fa fa-book-open-reader"></page-header>
    <loading-gif :loading-name="initLoadingName"></loading-gif>
    <b-row v-if="!isLoading(this.initLoadingName)">
      <b-col cols="8">
        <div>
          <ul class="app-menu">
            <li>
              <b-form @submit="onSearch" id="search-activity-form">
                <b-form-group
                  id="search-group"
                  label-for="search-publications"
                  description="Rechercher d'une publication"
                >
                  <b-form-row>
                    <b-col cols="9">
                      <b-input-group prepend="Recherche" class="mt-3 input-group-primary">
                        <b-form-input
                          id="search-publications"
                          type="search"
                          placeholder="Entrez le nom d'un article, d'un auteur ou un mot-clef"
                          v-model="searchText"
                          autocomplete="off"
                          :class="{ filled: searchText }"
                        >
                        </b-form-input>
                        <b-input-group-append>
                          <b-button type="submit" variant="primary">
                            <i class="fa fa-search"></i>
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-col>
                    <b-col class="text-right">
                      <div class="first-row-btn-holder">
                        <i class="fa fa-plus"></i>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="first-row-btn-holder" title="Ajout d'une publication" v-b-tooltip>
                        <router-link :to="{ name: 'publications-add', }">
                          <i class="fa fa-book-reader icon-button-big"></i>
                        </router-link>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="first-row-btn-holder" title="Ajout d'un abonnement" v-b-tooltip>
                        <router-link :to="{ name: 'subscriptions-add', }">
                          <i class="fa fa-envelopes-bulk icon-button-big"></i>
                        </router-link>
                      </div>
                    </b-col>
                  </b-form-row>
                </b-form-group>
              </b-form>
            </li>
            <li v-for="elt of displayedPublicationTypes" :key="elt.publicationType.id">
              <b-row>
                <b-col cols="4" class="small-text">
                  <i class="fa fa-book-open"></i>
                  {{ elt.publicationType.publicationTitle.name }} - {{ elt.publicationType.name }}
                  <div class="small-text" v-if="elt.publicationType.publicationsCount">
                    <a href @click.prevent="loadPublications(elt)" :class="{ active: showPublications }">
                      <counter-label :counter="elt.publicationType.publicationsCount" label="publication">
                      </counter-label>
                    </a>
                  </div>
                  <div class="small-text" v-if="elt.publicationType.subscriptionsCount">
                    <a href @click.prevent="loadSubscriptions(elt)" :class="{ active: showSubscriptions }">
                      <counter-label :counter="elt.publicationType.subscriptionsCount" label="abonnement">
                      </counter-label>
                    </a>
                  </div>
                </b-col>
                <b-col cols="6" class="small-text">
                  <div v-if="elt.latestPublication">
                    <a
                      href
                      @click.prevent="showLatestPublication(elt.latestPublication)"
                    >
                      {{ elt.latestPublication.shortName() }}
                    </a>
                    <span class="badge badge-dark" v-b-tooltip title="Ventes">
                      {{ elt.latestPublication.copiesQuantity }}
                    </span>
                    <span class="badge badge-success" v-b-tooltip title="Ventes au numéro">
                      {{ elt.latestPublication.soldCopiesQuantity }}
                    </span>
                    <span class="badge badge-warning" v-b-tooltip title="Dépôt">
                      {{ elt.latestPublication.depositCopiesQuantity }}
                    </span>
                  </div>
                  <div v-if="elt.latestSubscription">
                    <a href @click.prevent="showLatestSubscription(elt.latestSubscription)">
                      {{ elt.latestSubscription.shortName() }}
                    </a>
                    <span class="badge badge-danger" v-b-tooltip title="Abonnés">
                      {{ elt.latestSubscription.subscriptorsQuantity }}
                    </span>
                  </div>
                </b-col>
                <b-col class="text-right">
                  <div v-if="showPublications || showSubscriptions">
                    <a class="btn btn-xs btn-back-all btn-secondary" href @click.prevent="onReset()">
                       <i class="fa fa-chevron-left"></i> Retour
                     </a>
                  </div>
                </b-col>
              </b-row>
            </li>
            <li v-if="showMenu">
              <router-link :to="{ name: 'publications-deposits', }">
                <i class="fa fa-layer-group"></i> Dépôts
              </router-link>
            </li>
            <li v-if="searchText && !isLoading(loadingName)">
              <b-row>
                <b-col cols="9">
                  <b>
                    Recherche:
                    <counter-label :counter="publications.length" label="publication"></counter-label>
                  </b>
                </b-col>
                <b-col class="text-right">
                  <div v-if="showPublications">
                    <a class="btn btn-xs btn-back-all btn-secondary" href @click.prevent="onReset()">
                       <i class="fa fa-chevron-left"></i> Retour
                     </a>
                  </div>
                </b-col>
              </b-row>
            </li>
            <loading-gif :loading-name="loadingName"></loading-gif>
            <li
              v-for="publication of publications" :key="publication.id"
            >
              <router-link
                :to="{ name: 'publications-edit', params: {'publicationId': publication.id, }}"
              >
                {{ publication.fullName() }}
              </router-link>
              <div class="small-text">
                <div>
                  <span v-for="author of publication.authors" :key="author.id" class="badge badge-light">
                    {{ author.individual.firstAndLastName() }}
                  </span>
                </div>
                <div v-for="article of publication.articles" :key="article.id" class="art-title">
                  {{ article.title }}
                  <span v-for="author of article.authors" :key="author.id" class="badge badge-light">
                    {{ author.individual.firstAndLastName() }}
                  </span>
                  <div>
                    <span v-for="keyword of article.keywords" :key="keyword.id" class="badge badge-dark">
                      #{{ keyword.keyword }}
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <li
              v-for="subscription of subscriptions" :key="subscription.id"
            >
               <router-link
                  :to="{ name: 'subscriptions-edit', params: {'subscriptionId': subscription.id, }}"
                >
                  {{ subscription.fullName() }}
                </router-link>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="recent-items text-right">
          <h3>Accès récent</h3>
          <p v-if="recentAccess.length === 0">-</p>
            <div v-for="elt in recentAccess" :key="elt.id" class="recent-line">
              <router-link
                :to="{ name: 'publications-edit', params: {'publicationId': elt.id, }}"
              >
                {{ elt.fullName() }}
              </router-link>
            </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store'
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makePublication, makePublicationType, makeSubscription } from '@/types/publications'
import router from '@/router'

export default {
  name: 'publications-home',
  mixins: [BackendMixin],
  components: {
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      loadingName: 'publications-home',
      initLoadingName: 'publications-home-init',
      searchText: '',
      publications: [],
      publicationsDisplayed: false,
      recentAccess: [],
      publicationsCount: 0,
      subscriptions: [],
      subscriptionsDisplayed: false,
      subscriptionsCount: 0,
      loaded: false,
      publicationTypes: [],
      selectedPublicationType: null,
    }
  },
  watch: {
  },
  computed: {
    title() {
      return store.getters.config.publicationTitle
    },
    showPublications() {
      return this.publicationsDisplayed && !this.isLoading(this.loadingName)
    },
    showSubscriptions() {
      return this.subscriptionsDisplayed && !this.isLoading(this.loadingName)
    },
    showMenu() {
      return !this.subscriptionsDisplayed && !this.publicationsDisplayed && !this.isLoading(this.loadingName)
    },
    displayedPublicationTypes() {
      if (this.searchText) {
        return []
      } else if (this.selectedPublicationType) {
        return this.publicationTypes.filter(elt => elt.publicationType.id === this.selectedPublicationType.id)
      } else {
        return this.publicationTypes
      }
    },
  },
  async mounted() {
    this.startLoading(this.initLoadingName)
    await this.loadHome()
    await this.loadRecent()
    this.searchText = this.$route.query.search || ''
    if (this.searchText) {
      await this.searchPublications(this.searchText)
    }
    this.loaded = true
    this.endLoading(this.initLoadingName)
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    onSearch(evt) {
      evt.preventDefault()
      this.searchPublications(this.searchText)
      this.updateRoute()
    },
    updateRoute() {
      if (this.loaded) {
        const query = {}
        if (this.searchText) {
          query.search = this.searchText
        }
        if (this.selectedPublicationType) {
          query.type = this.selectedPublicationType.id
        }
        router.push({ path: this.$route.path, query: query, })
      }
    },
    async loadRecent() {
      let url = '/publications/api/publications/recently_accessed/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.recentAccess = resp.data.map(makePublication)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadHome() {
      let url = '/publications/api/publications-home/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        for (const elt of resp.data) {
          this.publicationTypes.push(
            {
              publicationType: makePublicationType(elt),
              latestPublication: elt['latest_publication'] ? makePublication(elt['latest_publication']) : null,
              latestSubscription: elt['latest_subscription'] ? makeSubscription(elt['latest_subscription']) : null,
            }
          )
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async searchPublications(text) {
      this.publications = []
      if (text) {
        this.startLoading(this.loadingName)
        try {
          let url = '/publications/api/publications/search/?text=' + text
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.publications = resp.data.map(makePublication)
          this.publicationsDisplayed = true
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      }
    },
    async loadPublications(elt) {
      this.searchText = ''
      this.selectedPublicationType = elt.publicationType
      this.updateRoute()
      this.onReset(true)
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/publications/?type=' + elt.publicationType.id
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.publications = resp.data.map(makePublication)
        this.publicationsDisplayed = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadSubscriptions(elt) {
      this.searchText = ''
      this.selectedPublicationType = elt.publicationType
      this.updateRoute()
      this.onReset(true)
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/subscriptions/?type=' + elt.publicationType.id
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.subscriptions = resp.data.map(makeSubscription)
        this.subscriptionsDisplayed = true
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    onReset(load = false) {
      this.publications = []
      this.publicationsDisplayed = false
      this.subscriptions = []
      this.subscriptionsDisplayed = false
      this.searchText = ''
      if (!load) {
        this.selectedPublicationType = null
      }
      this.updateRoute()
    },
    showLatestPublication(pub) {
      this.publications = [pub]
      this.selectedPublicationType = pub.publicationType
      this.publicationsDisplayed = true
      this.subscriptions = []
      this.subscriptionsDisplayed = false
    },
    showLatestSubscription(sub) {
      this.publications = []
      this.selectedPublicationType = sub.publicationType
      this.publicationsDisplayed = false
      this.subscriptions = [sub]
      this.subscriptionsDisplayed = true
    },
  },
}
</script>

<style scoped lang="less">
.art-title {
  span {
    margin-right: 1px;
    margin-bottom: 1px;
    vertical-align: top;
  }
}
.app-menu.small-text li {
  font-size: 14px;
}
.btn-plus {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  font-size: 20px;
}
.first-row-btn-holder {
  margin-top: 8px;
}
.active {
  font-weight: bold;
  padding: 2px;
  background: #fbff8d;
}
li .badge {
  margin: 0 1px;
}
</style>
